import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Token extends Component {

    render() {
        return (

            <div class="boxWrap2Token">

                <div class="glitch-wrapper">
                    <div id = "container" /*class="glitch" data-text="TOKEN"*/>
                        <h1>TOKEN</h1>
                        <h1>TOKEN</h1>
                        <h1>TOKEN</h1>                        
                        </div>
                </div>


                <div class="tokenM">
                    <div class="storyConT">
                        <p></p>
                        <p class="divide">
                            <div class="tokenHSub">Token supply:</div>
                            <div class="tokenH2">210,000,000,0 tokens</div>
                        </p>

                        <p class="divide">
                            <div class="tokenHSub">Token distribution:</div>
                            <div class="tokenH2">In the early days of Zkdoge, 100% of zkdoge tokens will be distributed fairly and freely in the community</div>
                        </p>

                        <p class="divide">
                            <div class="tokenHSub">Airdrop:</div>
                            <div class="tokenH2">45% of zkdoge tokens will be distributed to our Zksync doge NFT collection holders, early community contributors</div>
                        </p>

                        <p class="divide">
                            <div class="tokenHSub">Liquidity:</div>
                            <div class="tokenH2">55% of zkdoge tokens are used to provide project liquidity</div>
                        </p>
                    </div>
                </div>

            </div>
        )
    }
}

export default Token;

