import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class Story extends Component {

    render() {
        return (

            <div class="boxWrap2Story">

                    <div class="glitch-wrapper">
                        <div id="container" /*class="glitch" data-text="ABOUT OUR PROJECT"*/>
                            <h1>ABOUT PROJECT</h1>
                            <h1>ABOUT PROJECT</h1>
                            <h1>ABOUT PROJECT</h1>
                            </div>
                    </div>

                    <div class="about">
                        <div class="storyCon">
                            <p class="storyCon3">Zksyncdoge NFT is a series of community NFT collections for all members of zksync. Holders have the opportunity to participate in the revolutionary ideas that zksync-doge may bring to Web3 and receive super rewards of $Zkdoge tokens!</p>

                        </div>
                    </div>


            </div>
        )
    }
}

export default Story;

