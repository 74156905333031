import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import logo from '../assets/logo_.png';
import Ticker from './ticker';
import os from '../assets/os.png';
import twitter from '../assets/twitter.png';
import elementLink from '../assets/elementLogo.png'
 
const mintsquare = () => {
	window.open("https://mintsquare.io/collection/zksync/0x0636fbd5a3f93b76bae3d177157d217441698783");
}

const tweet = () => {
	window.open("https://twitter.com/zksync_doge");
}

const tg = () => {
	window.open("#");
}

const element = () => {
	window.open("https://element.market/collections/zksyncdoge?search%5Btoggles%5D[0]=BUY_NOW");
}

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}


class Footer extends Component {




    render() {
        return (

            <div class="boxWrap2Footer">
 					<Ticker />

                <div class="footer">

                    <div><img class="logoF" src={logo} /></div>
                    <div class="icons">
                        <div class="discord"><img class="osPic" onClick={mintsquare} src={os} /></div>
                        <div class="discord"><img class="osPic" onClick={element} src={elementLink} /></div>
                        <div class="discord"><img class="osPic" onClick={tweet} src={twitter} /></div>


                     </div>

                    <div class="iconsMob">
                        <div class="discord"><img class="osPic" onClick={mintsquare} src={os} /></div>
                        <div class="discord"><img class="osPic" onClick={element} src={elementLink} /></div>
                        <div class="discord"><img class="osPic" onClick={tweet} src={twitter} /></div>


                     </div>
                    <div class="copyright">Copyright © 2023 Zksyncdoge. All Rights Reserved</div>
                </div>

            </div>
        )
    }
}

export default Footer;

