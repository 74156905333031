import React, { Component, useEffect } from 'react';
import '../App.css';
import "aos/dist/aos.css";
import Aos from 'aos';
import Carousel from './carousel';

let nft = "https://media.discordapp.net/attachments/1008571037862080542/1100428832621744279/NFTHouse9_shiba_inu_cyber_punk_theme_d45df8d0-051b-4629-b29b-16ac278bc798.png?width=418&height=418"

const Stry = () => {
    useEffect(() => {
        Aos.init({ duration: 4000 });
    }, [])
}

class NFTproject extends Component {

    render() {
        return (

            <div class="boxWrap2NFT">

                <div class="glitch-wrapper">
                    <div id="container" /*class="glitch" data-text="What is zkdoge?"*/>
                        
                        <h1>What is zkdoge?</h1>
                        <h1>What is zkdoge?</h1>
                        <h1>What is zkdoge?</h1>
                        
                        </div>
                </div>

                <div class="about2">
                     <img src={nft}/>
                        <p class="storyCon2">zkdoge is a community token, zkdoge belongs to everyone in the zksync community, it will be used on the zksync ecological application, and it is also a necessary certificate to participate in zksyncdoge, we will build an interesting and open community with digital content creators Work closely with promoters to promote the development of the digital asset ecosystem. We encourage community members to actively participate in community building and development, share knowledge and experience, and provide support and feedback to the community</p>
                </div>

                <Carousel />

            </div>
        )
    }
}

export default NFTproject;

